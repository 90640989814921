import { en } from 'vuetify/locale'

export default {
  ...en,
  application: 'Application',
  applications: 'Applications',
  createApplication: 'Create application',
  organization: 'Organization',
  courses: 'Courses',
  addCourse: 'Add courses',
  dashboard: 'Dashboard',
  more: 'More',
  forms: 'Forms',
  exams: 'Exams',
  exam: 'Exam',
  createExam: 'Create exam',
  createExams: 'Create exams',
  createSchool: 'Create school',
  createAdmin: 'Create admin',
  createTeacher: 'Create teacher',
  createStudent: 'Create student',
  createCourse: 'Create course',
  addTeacher: 'Add teacher',
  newExam: 'New exam',
  login: 'Login',
  forgotPassword: 'Forgot password',
  resetPassword: 'Recover password',
  clear: 'Clear',
  logout: 'Logout',
  email: 'E-mail',
  name: 'Name',
  password: 'Password',
  loginWithGoogle: 'Login with Google',
  loginWithApple: 'Login with Apple',
  loginWithMicrosoft: 'Login with Microsoft',
  register: 'Register',
  input: {
    appendAction: 'Show password'
  },
  questions: 'Questions',
  myQuestions: 'My questions',
  me: 'me',
  others: 'others',
  questionsDatabase: 'Questions database',
  organizationQuestions: 'Organization questions',
  createQuestion: 'Create question',
  search: 'Search',
  objective: 'Single choice',
  multipleChoice: 'Multiple choice',
  trueOrFalse: 'True or false',
  openEnded: 'Open ended',
  connectTheDots: 'Connect the dots',
  matrix: 'Matrix',
  fillInTheBlanks: 'Fill in the blanks',
  sorting: 'Sorting',
  slider: 'Slider',
  title: 'Title',
  blank: 'Blank',
  seeAllModels: 'See all models',
  perPage: 'Per page',
  filter: 'Filter',
  myExams: 'My exams',
  untitledExam: 'Untitled exam',
  view: 'View',
  apply: 'Apply',
  duplicate: 'Duplicate',
  'new-question': 'New question',
  'exam-application': 'Exam application',
  personalInfos: 'Personal informations',
  schoolInfos: 'School informations',
  organizationInfos: 'Organization informations',
  schoolInfosDescription:
    'This will be displayed in the applications made by you and in areas of the Pruvo platform.',
  personalInfosDescription: 'This will be displayed in the applications made by you.',
  accessInfos: 'Access informations',
  accessInfosDescription: 'Login and security data',
  changePassword: 'Change password',
  repeatPassword: 'Repeat password',
  teachingStages: 'Teaching stages',
  teachingStagesDescription:
    'Add or remove your organization teaching milestones in the field on the side.',
  add: 'Add',
  schoolGrades: 'School grades',
  schoolGradesDescription: 'Add or remove your organization grades in the field on the side.',
  shifts: 'Shifts',
  shiftsDescription: 'Add or remove your organization shifts in the field on the side.',
  untitledQuestion: 'Untitled question',
  myApplications: 'My applications',
  organizationInfo: 'Organization',
  import: 'Import',
  students: 'Students',
  teacher: 'Teacher',
  editQuestion: 'Edit question',
  copyToMyQuestions: 'Copy to my questions',
  delete: 'Delete',
  student: 'Student',
  showApplicationsFromForms: 'Show applications from form',
  exportToForms: 'Export to Google Forms',
  save: 'Save',
  saveAndApply: 'Save and apply',
  myPlan: 'My plan',
  executedAplications: 'Executed applications',
  configs: 'Configurations',
  classroom: 'Google Classroom',
  classroomDescription: 'Connect your organization to Google Classroom',
  print: 'Print',
  'exam-details': 'Exam details',
  versionHistory: 'Version history',
  preview: 'Preview',
  schoolsStudentLogin: 'QR Code student login',
  coursesStudentLogin: 'QR Code student login',
  studentsLogin: 'QR Code student login',
  examsModel: 'Exams model',
  saveAsModel: 'Save as exam model',
  saveModel: 'Save model',
  'exam-model': 'Edit exam model',
  edit: 'Edit',
  knowledgeAreaDescription:
    'Add or remove your organization knowledge area in the field on the side.',
  knowledgeAreas: 'Knowledge areas',
  curricularComponentsDescription:
    'Add or remove your organization curricular component in the field on the side.',
  curricularComponents: 'Curricular components',
  'support-material': 'Support material',
  'support-material-student': 'Support material - Student',
  'support-material-teacher': 'Support material - Teacher',
  'support-material-coordinator': 'Support material - Coordinator',
  'support-material-student-monitoring': 'Support material - Student monitoring',
  impersonate: 'Impersonate',
  'class-plans': 'Lesson plans'
}
